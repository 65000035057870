import React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import LayersIcon from "@mui/icons-material/Layers";
import Typography from "@mui/material/Typography";
import MuiButton from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import useStyle from "./styles";
import Button from "../../../../../components/button";
import ProjectEBITDAFormModal from "../../../../../components/project-EBITDA-form-modal";
import DetailsCard from "../../../../../components/details-card";
import ViewWrapper from "../../../../../components/view-wrapper";
import ConditionalProtect from "../../../../../components/conditional-protect";
import { setDeleteModalPropsAction } from "../../../../../utils/redux/slices";
import { USER_PERMISSIONS } from "../../../../../constants";
import { useAPI } from "../../../../../utils/hooks";
import {
  cn,
  numberToUSD,
  numberWithCommas,
} from "../../../../../utils/helpers";
import {
  IProjectEBITDA,
  IProjectTiming,
  IProjectEBITDA_Form,
  IProjectEBITDA_FormErrors,
} from "../../../../../interfaces";

interface IProps {
  getProjectTiming: (projectId: number) => Promise<IProjectTiming[]>;
  getProjectEBITDA: (projectId: number) => Promise<IProjectEBITDA[]>;
  updateProjectEBITDA: (
    projectId: number,
    scheduleOverrideId: number,
    form: IProjectEBITDA_Form,
  ) => Promise<IProjectEBITDA>;
}

export default function ProjectEBITDAView({
  getProjectTiming,
  getProjectEBITDA,
  updateProjectEBITDA,
}: IProps): JSX.Element {
  const styles = useStyle();

  const dispatch = useDispatch();

  const { projectId } = useParams();

  const [editModalTitle, setEditModalTitle] = React.useState<
    "Override EBITDA" | "Edit EBITDA Override"
  >("Edit EBITDA Override");
  const [projectEBITDA, setProjectEBITDA] = React.useState<IProjectEBITDA>();
  const [dateSchedule, setDateSchedule] = React.useState<string[]>([]);
  const [form, setForm] = React.useState<IProjectEBITDA_Form>({
    ebitda: [],
  });

  const [updateEBITDAModalOpen, setUpdateEBITDAModalOpen] =
    React.useState<boolean>(false);
  const [visibleEbitdaRows, setVisibleEbitdaRows] = React.useState<number>(18);

  React.useEffect(() => {
    getProjectEBITDACallAPI(Number(projectId)).then((response) => {
      response && setProjectEBITDA(response[0]);
    });
    getProjectTimingCallAPI(Number(projectId)).then((response) => {
      response && setDateSchedule(response[0]?.date_schedule || []);
    });
  }, [projectId]);

  const { callAPI: getProjectTimingCallAPI } = useAPI((projectId: number) =>
    getProjectTiming(projectId),
  );

  const {
    callAPI: getProjectEBITDACallAPI,
    errored: getEBITDAFailed,
    loading: loadingEBITDA,
  } = useAPI((projectId: number) => getProjectEBITDA(projectId), {
    initialLoading: true,
  });

  const {
    callAPI: updateProjectEBITDACallAPI,
    fieldErrors: updateProjectEBITDAFormErrors,
    setFieldErrors: setUpdateProjectEBITDAFormErrors,
    loading: updatingEBITDA,
  } = useAPI<IProjectEBITDA, IProjectEBITDA_FormErrors>(
    (projectId: number, id: number, form: IProjectEBITDA_Form) =>
      updateProjectEBITDA(projectId, id, form),
  );

  const handleOnUpdateProjectEBITDA = async (form: IProjectEBITDA_Form) => {
    const scheduleOverride = await updateProjectEBITDACallAPI(
      Number(projectId),
      Number(projectEBITDA?.id),
      form,
    );
    scheduleOverride && setProjectEBITDA(scheduleOverride);
    return scheduleOverride;
  };

  const handleOpenUpdateProjectEBITDAModal = () => {
    setEditModalTitle("Override EBITDA");
    if (!projectEBITDA?.ebitda?.length) {
      setEditModalTitle("Edit EBITDA Override");
    }
    const { created, created_by, id, modified, project, ...formDetails } =
      projectEBITDA!;

    const formData: IProjectEBITDA_Form = {
      ...formDetails,
    };

    if (formData.ebitda === null) {
      formData.ebitda = [];
    }

    setForm({ ebitda: formData.ebitda });
    setUpdateEBITDAModalOpen(true);
  };

  const handleCloseUpdateProjectEBITDAModal = () => {
    setUpdateEBITDAModalOpen(false);
  };

  const toggleShowMoreEbitdaRows = () => {
    setVisibleEbitdaRows((prevState) => {
      const ebitdaLength = projectEBITDA?.ebitda?.length ?? 0;
      return prevState === 18 ? ebitdaLength : 18;
    });
  };

  const onDeletProjectEbitda = async () => {
    // not calling the delete api. instead calling update api and sending ebitda as null
    const updatedEbitda = await updateProjectEBITDACallAPI(
      projectId,
      projectEBITDA?.id,
      {
        ebitda: null,
      },
    );
    updatedEbitda && setProjectEBITDA(updatedEbitda);
  };

  const handleOnDeleteClick = () => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete EBITDA",
        description: "Are you sure you want to delete?",
        onConfirm: onDeletProjectEbitda,
      }),
    );
  };

  return (
    <>
      <ViewWrapper loading={loadingEBITDA} error={getEBITDAFailed}>
        <Box>
          {!projectEBITDA?.ebitda?.length ? (
            <Box className={styles.classes.emptyContainer}>
              <Box>
                <ConditionalProtect
                  type="project"
                  permission={USER_PERMISSIONS.PROJECTS_CRUD}
                >
                  <Button
                    canOpenUpgrade
                    startIcon={<LayersIcon />}
                    label="Override EBITDA"
                    onClick={handleOpenUpdateProjectEBITDAModal}
                    btnType="primary"
                    className={styles.classes.createBtn}
                  />
                </ConditionalProtect>
                <Typography
                  variant="body1"
                  className={styles.classes.createInfo}
                >
                  You can override the EBITDA by providing a schedule for it.
                </Typography>
              </Box>
            </Box>
          ) : null}
          {projectEBITDA?.ebitda?.length ? (
            <Box>
              <Box className={cn("flex justify-end gap-2 mt-2 mb-4")}>
                <ConditionalProtect
                  type="project"
                  permission={USER_PERMISSIONS.PROJECTS_CRUD}
                >
                  <Button
                    canOpenUpgrade
                    label="Delete"
                    btnType="danger"
                    onClick={handleOnDeleteClick}
                  />
                  <Button
                    canOpenUpgrade
                    startIcon={<EditIcon />}
                    label="Edit"
                    btnType="primary"
                    onClick={handleOpenUpdateProjectEBITDAModal}
                  />
                </ConditionalProtect>
              </Box>
              <Box className={cn("grid md:grid-cols-2 gap-4")}>
                <DetailsCard
                  heading={`EBITDA (${projectEBITDA.ebitda.length})`}
                  autoHeight
                  actionButton={
                    projectEBITDA.ebitda.length > 18 ? (
                      <Box className={cn("flex justify-end")}>
                        <MuiButton
                          onClick={toggleShowMoreEbitdaRows}
                          classes={{
                            root: cn("!text-secondary !capitalize"),
                          }}
                        >
                          {visibleEbitdaRows === projectEBITDA.ebitda.length
                            ? "Show Less"
                            : "Show More"}
                        </MuiButton>
                      </Box>
                    ) : undefined
                  }
                  sections={[
                    {
                      fields: (projectEBITDA.ebitda || [])
                        .map((e, i) => ({
                          label: dateSchedule[i],
                          value: { text: numberWithCommas.format(e) },
                        }))
                        .slice(0, visibleEbitdaRows),
                    },
                  ]}
                  postActionButtonSections={[
                    {
                      fields: [
                        {
                          label: "Total",
                          value: {
                            text: numberToUSD.format(
                              projectEBITDA.schedule_total || 0,
                            ),
                          },
                          textWeight: 600,
                        },
                      ],
                    },
                  ]}
                />
              </Box>
            </Box>
          ) : null}
        </Box>
      </ViewWrapper>

      <ProjectEBITDAFormModal
        open={updateEBITDAModalOpen}
        form={form}
        loading={updatingEBITDA}
        formErrors={updateProjectEBITDAFormErrors}
        setFormErrors={setUpdateProjectEBITDAFormErrors}
        dateSchedule={dateSchedule}
        headerLabel={editModalTitle}
        setForm={setForm}
        onClose={handleCloseUpdateProjectEBITDAModal}
        onConfirm={handleOnUpdateProjectEBITDA}
      />
    </>
  );
}
