import React from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { useLocation } from "react-router-dom";

import Button from "../../../../components/button";
import TextInput from "../../../../components/text-input";
import ProjectCostTable from "./project-cost-table";
import ConditionalProtect from "../../../../components/conditional-protect";
import { cn } from "../../../../utils/helpers";
import { PROJECT_COST_TYPE } from "../../../../constants";
import { IProjectCostItem, TUserPermission } from "../../../../interfaces";

interface IProps {
  projectId: string;
  projectCostId: string;
  costItems: IProjectCostItem[];
  viewType: string;
  handleUpdate: () => void;
  handleOpenAddCostModal: () => void;
  withPadding?: boolean;
  title?: string;
  permission: TUserPermission;
}

export default function ProjectCosts({
  projectCostId,
  projectId,
  costItems,
  viewType,
  handleUpdate,
  handleOpenAddCostModal,
  withPadding,
  title,
  permission,
}: IProps) {
  const [searchString, setSearchString] = React.useState("");
  const location = useLocation();
  const isCasePage = location.pathname.includes("case");

  const onSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const getTotalCost = (type: keyof typeof PROJECT_COST_TYPE) => {
    return (
      costItems
        .filter((r) => r.cost_type === type)
        .reduce((acc, item) => {
          return acc + item.cost_in_dollars;
        }, 0) || 0
    );
  };

  const groupedData = React.useMemo(() => {
    if (!searchString) {
      return groupCostsByType(costItems); // No filtering if searchString is empty
    }
    // Filter based on searchString
    const filteredItems = groupCostsByType(
      costItems.filter((item) => {
        const lowerCaseItemName = JSON.stringify(item.name).toLowerCase(); // Convert item to lowercase string
        return lowerCaseItemName.includes(searchString.toLowerCase());
      }),
    );

    return filteredItems;
  }, [costItems, searchString]);

  // Filter based on searchString
  const filteredRows = React.useMemo(() => {
    return costItems.filter((item) =>
      item.name.toLowerCase().includes(searchString.toLowerCase()),
    );
  }, [costItems, searchString]);

  function groupCostsByType(costItems: IProjectCostItem[] | undefined) {
    if (!costItems) return {};
    return _.groupBy(costItems, "cost_type");
  }

  const totalCosts = React.useMemo(() => {
    return costItems.reduce((acc, item) => acc + item.cost_in_dollars, 0);
  }, [costItems]);

  return (
    <Box>
      <Box
        className={cn(
          `flex justify-between items-start ${withPadding && "p-2"}`,
        )}
      >
        <Box className={cn("flex flex-col gap-4 my-1")}>
          {title && (
            <Typography className={cn("!font-bold")}>{title}</Typography>
          )}
          <TextInput
            label="Search"
            placeholder="Name"
            onChange={onSearchStringChange}
            value={searchString}
          />
        </Box>
        <ConditionalProtect
          type={isCasePage ? "deal" : "project"}
          permission={permission}
        >
          <Button
            btnType="primary"
            label="Add Cost"
            onClick={handleOpenAddCostModal}
            startIcon={<AddIcon />}
          />
        </ConditionalProtect>
      </Box>
      {costItems && viewType === "single" && (
        <Box className={cn("grid gap-4 my-2")}>
          <Paper>
            <ProjectCostTable
              title="Development Cost"
              rows={groupedData["DEV"] || []}
              projectId={projectId.toString()}
              costId={projectCostId.toString()}
              onUpdate={handleUpdate}
              totalCost={getTotalCost("DEV")}
              permission={permission}
            />
          </Paper>
          <Paper>
            <ProjectCostTable
              title="Acquisition Cost"
              rows={groupedData["ACQ"] || []}
              projectId={projectId.toString()}
              costId={projectCostId.toString()}
              onUpdate={handleUpdate}
              totalCost={getTotalCost("ACQ")}
              permission={permission}
            />
          </Paper>
          <Paper>
            <ProjectCostTable
              title="Interconnection Cost"
              rows={groupedData["INT"] || []}
              projectId={projectId.toString()}
              costId={projectCostId.toString()}
              onUpdate={handleUpdate}
              totalCost={getTotalCost("INT")}
              permission={permission}
            />
          </Paper>
          <Paper>
            <ProjectCostTable
              title="Build Cost"
              rows={groupedData["BLD"] || []}
              projectId={projectId.toString()}
              costId={projectCostId.toString()}
              onUpdate={handleUpdate}
              totalCost={getTotalCost("BLD")}
              permission={permission}
            />
          </Paper>
          <Paper>
            <ProjectCostTable
              title="Transaction Cost"
              rows={groupedData["TRN"] || []}
              projectId={projectId.toString()}
              costId={projectCostId.toString()}
              onUpdate={handleUpdate}
              totalCost={getTotalCost("TRN")}
              permission={permission}
            />
          </Paper>
          <Paper>
            <ProjectCostTable
              title="Land Cost"
              rows={groupedData["LND"] || []}
              projectId={projectId.toString()}
              costId={projectCostId.toString()}
              onUpdate={handleUpdate}
              totalCost={getTotalCost("LND")}
              permission={permission}
            />
          </Paper>
          <Paper>
            <ProjectCostTable
              title="Other Cost"
              rows={groupedData["OTH"] || []}
              projectId={projectId.toString()}
              costId={projectCostId.toString()}
              onUpdate={handleUpdate}
              totalCost={getTotalCost("OTH")}
              permission={permission}
            />
          </Paper>
        </Box>
      )}
      {costItems && viewType === "all" && (
        <ProjectCostTable
          title="Costs"
          rows={filteredRows}
          projectId={projectId.toString()}
          costId={projectCostId.toString()}
          onUpdate={handleUpdate}
          totalCost={totalCosts}
          permission={permission}
          disableExpand
          disableTitle
          disableNavigation
        />
      )}
    </Box>
  );
}
